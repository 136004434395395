@import 'variables';

.component-button,
.news .article a.more {
  display: inline-block;
  padding: 0 1.875rem;
  height: 3rem;
  font-size: 1.125rem;
  letter-spacing: 0.015em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.component-button .icon,
.news .article a.more .icon {
  display: flex;
  left: 1.875rem;
}
.component-button .input,
.news .article a.more .input,
.component-button .text,
.news .article a.more .text {
  margin-left: 1.5rem;
}
.component-button--primary {
  background-color: #353434;
  color: #fff;
  transition: 0.15s all ease-in-out;
}
.component-button--primary .icon svg path {
  fill: #fff;
}

.component-button--primary.disabled {
  background-color: $color-grey-dark;
  color: $color-grey-mid;
}
.component-button--primary.disabled .icon svg path {
  fill: $color-grey-mid;
}
.component-button--primary.disabled:hover .icon svg path {
  fill: $color-grey-dark;
}
.component-button--primary.disabled:hover {
  background-color: $color-grey-mid;
  color: $color-grey-dark;
}

.component-button--primary.color-blue-dark {
  background-color: #05415a;
}
.component-button--primary:hover {
  background-color: #008cc8;
}
.component-button--primary.negative {
  background-color: #fff;
  color: #353434;
  transition: 0.15s all ease-in-out;
}
.component-button--primary.negative .icon svg path {
  fill: #353434;
  transition: 0.15s all ease-in-out;
}
.component-button--primary.negative.color-blue-dark {
  color: #05415a;
}
.component-button--primary.negative.color-blue-dark .icon svg path {
  fill: #05415a;
}
.component-button--primary.negative.color-white {
  color: #fff;
}
.component-button--primary.negative.color-white .icon svg path {
  fill: #fff;
}
.component-button--primary.negative:hover {
  background-color: #fff;
  color: #008cc8;
}
.component-button--primary.negative:hover .icon svg path {
  fill: #008cc8;
}
.component-button--secondary,
.component-button--link,
.news .article a.more {
  border: 1px solid #353434;
  color: #353434;
  transition: 0.15s all ease-in-out, 0.15s border ease-in-out;
}
.component-button--secondary .icon svg path,
.component-button--link .icon svg path,
.news .article a.more .icon svg path {
  fill: #353434;
  transition: 0.15s all ease-in-out;
}
.component-button--secondary.color-blue-dark,
.component-button--link.color-blue-dark,
.news .article button.color-blue-dark.more {
  border: 1px solid #05415a;
  color: #05415a;
}
.component-button--secondary.color-blue-dark .icon svg path,
.component-button--link.color-blue-dark .icon svg path,
.news .article a.color-blue-dark.more .icon svg path {
  fill: #05415a;
}
.component-button--secondary.color-white,
.component-button--link.color-white,
.news .article a.color-white.more {
  border: 1px solid #fff;
  color: #fff;
}
.component-button--secondary.color-white .icon svg path,
.component-button--link.color-white .icon svg path,
.news .article a.color-white.more .icon svg path {
  fill: #fff;
}
.component-button--secondary:hover,
.component-button--link:hover,
.news .article a.more:hover {
  border: 1px solid #008cc8;
  color: #008cc8;
}
.component-button--secondary:hover .icon svg path,
.component-button--link:hover .icon svg path,
.news .article a.more:hover .icon svg path {
  fill: #008cc8;
}
.component-button--link,
.news .article a.more {
  border: 0 !important;
  padding: 0;
  height: auto;
}
.component-button--link .icon,
.news .article a.more .icon {
  left: 0;
}
.component-button--link.color-blue-dark,
.news .article a.color-blue-dark.more,
.component-button--link.color-blue-light,
.news .article a.color-blue-light.more,
.component-button--link.color-white,
.news .article a.color-white.more,
.component-button--link:hover,
.news .article a.more:hover {
  border: 0 !important;
}
.component-button--button {
  cursor: pointer;
  width: auto;
  margin: 0;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}
.component-button--button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.component-button--white {
  border-color: #fff;
  color: #fff;
}
.component-button--white .icon svg path {
  fill: #fff;
}
.component-button--white:hover .icon svg path,
.component-button--white:focus .icon svg path {
  fill: #008cc8;
}
.component-button--scroll-down .icon {
  transform: rotate(-180deg);
}
.component-button--teaser {
  background-color: transparent;
  position: relative;
  height: 2.5rem;
  padding: 10px 26px 10px 34px;
  overflow: hidden;
  max-width: 100%;
}
.component-button--teaser::before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #008cc8;
  transform: skew(-15deg);
  transform-origin: 100% 100%;
}
.component-button--teaser .icon,
.component-button--teaser .text {
  z-index: 1;
}
.component-button--teaser .text {
  white-space: nowrap;
  overflow: hidden;
  transition: max-width 0.3s linear;
}
@media (min-width: 960px) {
  .component-button--teaser .text {
    max-width: 0px;
  }
}
.component-button--teaser:hover .text {
  max-width: 1000px;
}
.component-button--teaser.color-white {
  color: #fff;
}
.component-button--teaser.color-white .icon svg path {
  fill: #fff;
}
.component-button + .copy--smaller,
.news .article a.more + .copy--smaller,
.component-button + .copy p.copy--smaller,
.news .article a.more + .copy p.copy--smaller {
  max-width: 440px;
  margin-top: 1.25rem;
}
.component-button--job-apply {
  margin: 2.5rem 0;
}
.component-button--job-back {
  margin-top: 4.75rem;
}
.component-button--job-back .icon svg {
  transform: scaleX(-1);
}
@media (max-width: 840px) {
  .component-button,
  .news .article a.more {
    padding: 0 1.25rem;
    height: 40px;
  }
  .component-button .icon,
  .news .article a.more .icon {
    width: 24px;
  }
  .component-button .input,
  .news .article a.more .input {
    line-height: 1.3;
    margin-left: 1.25rem;
  }
}

.copy + .component-button,
.news .article .copy + a.more {
  margin-bottom: 3.75rem;
}
