// spacing variables
$spacing-xsmall: 0.625rem; // same as 10px
$spacing-small: 1.25rem; // same as 20px
$spacing-medium: 2.5rem; // same as 40px
$spacing-large: 3.75rem; // same as 60px
$spacing-xlarge: 5.625rem; // same as 90px
$spacing-xxlarge: 9.375rem; // same as 150px

// color variables
$color-black: #353434;
$color-grey-dark: #4a4a4a;
$color-grey-mid: #969696;
$color-grey-light: #eeeeee;
$color-grey-mine: #353434;
$color-white: #fff;
$color-blue-light: #008cc8;
$color-blue-dark: #05415a;
$color-error: #fa5019;

// breakpoints
$breakpoint-desktop-small: 1140px;

$breakpoint-tablet-large: 1023px;
$breakpoint-tablet-medium: 960px;
$breakpoint-tablet-small: 840px;
$breakpoint-tablet-xsmall: 760px;

$breakpoint-mobile-large: 620px;
$breakpoint-mobile-medium: 540px;
$breakpoint-mobile-small: 420px;
$breakpoint-mobile-xsmall: 320px;
