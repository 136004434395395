// mixins for text styling in forms
@mixin text-label() {
  font-family: 'Quay Sans';
  font-weight: 500;
  font-size: 16px;
  color: $color-grey-mine;
  line-height: 1.25em;
}
@mixin text-placeholder() {
  font-family: 'Quay Sans';
  //font-size: 18px;
  color: $color-grey-mid;
  //line-height: 1.44em;
}
@mixin text-placeholder--hover() {
  color: $color-black;
}
@mixin text-input() {
  font-family: 'Quay Sans';
  font-weight: 400;
  font-size: 1.125rem;
  color: $color-grey-mine;
  line-height: 1.44em;
  &:hover {
    color: $color-black;
  }
  &:focus,
  &:focus::placeholder {
    color: $color-blue-light;
  }
  &::placeholder {
    @include text-placeholder;
  }
  &:hover::placeholder {
    @include text-placeholder--hover;
  }
  &:disabled,
  &:disabled::placeholder {
    color: $color-grey-mid;
  }
  &::-ms-clear {
    display: none !important;
  }
  &.invalid {
    color: $color-error;
    &::placeholder {
      color: $color-grey-mid;
    }
  }
}

// specific inpput styles
@mixin input-padding() {
  padding: $spacing-xsmall;
}
@mixin input-box-style() {
  @include input-padding;
  background: $color-white;
  border: 1px solid $color-grey-light;
  border-radius: 0;
  transition: 0.15s all ease-in-out;
  &:hover {
    border-color: $color-blue-light;
  }
  &:focus {
    outline: none;
    border-color: $color-blue-light;
  }

  &:disabled {
    background-color: $color-grey-light;
    border-color: $color-grey-light;
    cursor: not-allowed;
  }
  &.invalid {
    border-color: $color-error;
  }
}

//mixins for specific form elements
@mixin label() {
  @include text-label;
  display: block;
  max-width: 440px;

  & + input,
  & + textarea,
  & + .choices {
    margin-top: 6px;
  }

  .disabled > & {
    color: $color-grey-light;
  }
}
@mixin input-text() {
  @include text-input;
  @include input-box-style;
  height: 3rem;
  width: 100%;
  max-width: 440px;
}
@mixin textarea() {
  @include text-input;
  @include input-box-style();
  width: 100%;
  min-height: 200px;
}
@mixin checkbox() {
  $checkbox-size: 1.5rem;
  position: absolute;
  opacity: 0;

  & + label {
    //different label text-styling than for other labels
    @include text-input;
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-left: $checkbox-size + $spacing-xsmall;
    &::before,
    &::after {
      position: absolute;
      content: '';
      height: $checkbox-size;
      width: $checkbox-size;
      left: 0px;
      top: 0px;
      display: inline-block;
    }
    //box
    &::before {
      border: 1px solid $color-grey-mine;
      transition: 0.15s all ease-in-out;
      background-color: transparent;
    }
    &:hover::before,
    &:focus::before {
      border-color: $color-blue-light;
    }
    //checkmark
    &::after {
      opacity: 0;
      transition: 0.15s opacity ease-in-out;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='17px' height='11px' viewBox='0 0 17 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Karriere' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Karriere_Jobsuche' transform='translate(-1388.000000, -1413.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group-3' transform='translate(1384.000000, 1406.000000)'%3E%3Cpolygon id='Rectangle' transform='translate(12.366791, 12.494202) scale(1, -1) rotate(-180.000000) translate(-12.366791, -12.494202) ' points='9.78191756 10.9884033 5.88037109 7 4 7 7.73358154 10.9884033 14.2335815 17.9884033 20.7335815 10.9884033 18.6852455 10.9884033 14.2335815 15.782503'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;
    }
    &:hover,
    &:focus {
      color: $color-blue-light;
    }
  }

  //checked state
  &:checked + label {
    &::before {
      background-color: $color-blue-light;
      border-color: $color-blue-light;
    }
    &::after {
      opacity: 1;
    }
  }
  //focus state
  &:focus + label {
    color: $color-blue-light;
    &::before {
      border-color: $color-blue-light;
    }
  }
}
@mixin hover($params) {
  transition: $params 0.15s ease-in-out;
}

@mixin hyphens {
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;

  -webkit-hyphens: auto;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-chars: 6 3 3;
  -webkit-hyphenate-limit-lines: 2;
  -webkit-hyphenate-limit-last: always;
  -webkit-hyphenate-limit-zone: 8%;

  -moz-hyphens: auto;
  -moz-hyphenate-limit-chars: 6 3 3;
  -moz-hyphenate-limit-lines: 2;
  -moz-hyphenate-limit-last: always;
  -moz-hyphenate-limit-zone: 8%;

  -ms-hyphens: auto;
  -ms-hyphenate-limit-chars: 6 3 3;
  -ms-hyphenate-limit-lines: 2;
  -ms-hyphenate-limit-last: always;
  -ms-hyphenate-limit-zone: 8%;
}

@mixin clearfix {
  zoom: 1;
  &:before,
  &:after {
    content: '\0020';
    display: block;
    height: 0;
    overflow: hidden;
  }
  &:after {
    clear: both;
  }
}

@mixin background-gradient($startColor: #3c3c3c, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($startColor),
    to($endColor)
  );
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image: -moz-linear-gradient(top, $startColor, $endColor);
  background-image: -ms-linear-gradient(top, $startColor, $endColor);
  background-image: -o-linear-gradient(top, $startColor, $endColor);
  background-image: linear-gradient(top, $startColor, $endColor);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

@mixin background-size($width: 100%, $height: 100%) {
  -moz-background-size: $width $height;
  -webkit-background-size: $width $height;
  background-size: $width $height;
}

@mixin border-radius($radius: 5px) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius-separate(
  $topLeftRadius: 5px,
  $topRightRadius: 5px,
  $bottomLeftRadius: 5px,
  $bottomRightRadius: 5px
) {
  -webkit-border-top-left-radius: $topLeftRadius;
  -webkit-border-top-right-radius: $topRightRadius;
  -webkit-border-bottom-right-radius: $bottomRightRadius;
  -webkit-border-bottom-left-radius: $bottomLeftRadius;

  -moz-border-radius-topleft: $topLeftRadius;
  -moz-border-radius-topright: $topRightRadius;
  -moz-border-radius-bottomright: $bottomRightRadius;
  -moz-border-radius-bottomleft: $bottomLeftRadius;

  border-top-left-radius: $topLeftRadius;
  border-top-right-radius: $topRightRadius;
  border-bottom-right-radius: $bottomRightRadius;
  border-bottom-left-radius: $bottomLeftRadius;
}

@mixin box($orient: horizontal, $pack: center, $align: center) {
  display: -webkit-box;
  display: -moz-box;
  display: box;

  -webkit-box-orient: $orient;
  -moz-box-orient: $orient;
  box-orient: $orient;

  -webkit-box-pack: $pack;
  -moz-box-pack: $pack;
  box-pack: $pack;

  -webkit-box-align: $align;
  -moz-box-align: $align;
  box-align: $align;
}

@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3c3c3c) {
  background-color: transparent;
  background-color: rgba($r, $g, $b, $opacity);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}',endColorstr='#{$color}');
  zoom: 1;
}

@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, 0.4), $inset: '') {
  @if $inset != '' {
    -webkit-box-shadow: $inset $x $y $blur $color;
    -moz-box-shadow: $inset $x $y $blur $color;
    box-shadow: $inset $x $y $blur $color;
  } @else {
    -webkit-box-shadow: $x $y $blur $color;
    -moz-box-shadow: $x $y $blur $color;
    box-shadow: $x $y $blur $color;
  }
}

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

@mixin columns($count: 3, $gap: 10) {
  -webkit-column-count: $count;
  -moz-column-count: $count;
  column-count: $count;

  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}

@mixin double-borders($colorOne: #3c3c3c, $colorTwo: #999999, $radius: 0) {
  border: 1px solid $colorOne;

  -webkit-box-shadow: 0 0 0 1px $colorTwo;
  -moz-box-shadow: 0 0 0 1px $colorTwo;
  box-shadow: 0 0 0 1px $colorTwo;

  @include border-radius($radius);
}

@mixin flex($value: 1) {
  -webkit-box-flex: $value;
  -moz-box-flex: $value;
  box-flex: $value;
}

@mixin flip($scaleX: -1) {
  -moz-transform: scaleX($scaleX);
  -o-transform: scaleX($scaleX);
  -webkit-transform: scaleX($scaleX);
  transform: scaleX($scaleX);
  filter: FlipH;
  -ms-filter: 'FlipH';
}

@mixin font-face($style-name, $file, $family) {
  $filepath: '../fonts/' + $family + '/' + $file;
  @font-face {
    font-family: $style-name;
    src: url('#{$filepath}.eot');
    src: url('#{$filepath}.eot?#iefix') format('embedded-opentype'),
      url('#{$filepath}.woff') format('woff'), url('#{$filepath}.ttf') format('truetype'),
      url('#{$filepath}.svg##{$style-name}') format('svg');
  }
}

@mixin outline-radius($radius: 5px) {
  -webkit-outline-radius: $radius;
  -moz-outline-radius: $radius;
  outline-radius: $radius;
}

@mixin resize($direction: both) {
  -webkit-resize: $direction;
  -moz-resize: $direction;
  resize: $direction;
}

@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
  -moz-transform: rotate($deg + deg);
  -o-transform: rotate($deg + deg);
  -webkit-transform: rotate($deg + deg);
  -ms-transform: rotate($deg + deg);
  transform: rotate($deg + deg);
  filter: progid:DXImageTransform.Microsoft.Matrix(
                     M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
  zoom: 1;
}

@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, 0.4)) {
  text-shadow: $x $y $blur $color;
}

@mixin transform($params) {
  -webkit-transform: $params;
  -moz-transform: $params;
  transform: $params;
}

@mixin transition($declarations...) {
  @each $prefix in '-webkit-', '-moz-', '-ms-', '-o-', '' {
    #{$prefix}transition: $declarations;
  }
}

@mixin triple-borders($colorOne: #3c3c3c, $colorTwo: #999999, $colorThree: #000000, $radius: 0) {
  border: 1px solid $colorOne;

  @include border-radius($radius);

  -webkit-box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
  -moz-box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
  box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
}
