@import 'variables';
@import 'mixins';

.form {
  //form element can be collection of label and input or other related fields
  .form-element {
    & + .form-element {
      margin-top: $spacing-small;
    }
    &--clear-option {
      input {
        padding-right: 24px !important;
      }
      input:placeholder-shown + .delete-input {
        opacity: 0;
        width: 0;
      }
      //x-icon to delete input
      .delete-input {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 30px;
        transition: opacity 200ms ease;
        cursor: pointer;
        &::after,
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -1px;
          right: 12px;
          display: block;
          width: 20px;
          height: 2px;
          background-color: $color-grey-mid;
          transition: background-color 200ms ease;
          transform-origin: center center;
        }
        &:hover {
          &::before,
          &::after {
            background-color: $color-blue-light;
          }
        }
        &::before {
          transform: rotate(-45deg);
        }
        &::after {
          transform: rotate(45deg);
        }
      }
    }
    // form element for curreny which has an euro icon
    .currency {
      // icon is normally grey
      background: url('../assets/img/euro\ _grey-mid.svg') no-repeat right;
      background-size: 20px;
      &:not(:placeholder-shown) {
        // icon is black if the placeholder text is shown
        background: url('../assets/img/euro_black.svg') no-repeat right;
        background-size: 20px;
      }
      &:focus {
        // icon is blue if the user clicked into the input
        background: url('../assets/img/euro\ _blue-light.svg') no-repeat right;
        background-size: 20px;
      }
      &:hover {
        &:focus {
          &:not(:placeholder-shown) {
            // icon is blue if the user clicked into the input and hovers over the input when the placeholder text is shown
            background: url('../assets/img/euro\ _blue-light.svg') no-repeat right;
            background-size: 20px;
          }
        }
        // icon is black if the user hovers over the input
        background: url('../assets/img/euro_black.svg') no-repeat right;
        background-size: 20px;
      }
    }

    .password-hide {
      display: flex;
      border-top-color: rgb(238, 238, 238);
      border-top-style: solid;
      border-top-width: 0.5px;
      border-right-color: rgb(238, 238, 238);
      border-right-style: solid;
      border-right-width: 0.5px;
      border-bottom-color: rgb(238, 238, 238);
      border-bottom-style: solid;
      border-bottom-width: 0.5px;
      border-left-color: rgb(238, 238, 238);
      border-left-style: solid;
      border-left-width: 0.5px;
      border-image-source: initial;
      border-image-slice: initial;
      border-image-width: initial;
      border-image-outset: initial;
      border-image-repeat: initial;
    }

    &--search {
      display: flex;
      width: 50%;
      padding-right: 20px;
      .input-wrapper {
        position: relative;
        width: 100%;
      }
      input[type='text'] {
        max-width: none;
      }
      input[type='password'] {
        max-width: none;
      }

      button {
        @include input-text;
        height: 48px;
        width: 88px;
        cursor: pointer;
        margin-left: 40px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMjUgMjUiIHdpZHRoPSIyNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjMuOTczNjg5NSAyMy4yNjkyODU3Yy4xOTUxODExLjE5NTE4MTIuMjcxNDA4LjQ3OTY2MzkuMTk5OTY2OC43NDYyODY0LS4wNzE0NDEzLjI2NjYyMjQtLjI3OTY5NzEuNDc0ODc4Mi0uNTQ2MzE5NS41NDYzMTk1LS4yNjY2MjI1LjA3MTQ0MTItLjU1MTEwNTItLjAwNDc4NTctLjc0NjI4NjQtLjE5OTk2NjlsLTYuMTExODI4Ny02LjExMTgyODdjLTQuMDk2MDk1OCAzLjUwOTAyMDEtMTAuMjMyOTcwODggMy4xNTQwMjY5LTEzLjg5NzEzNDY0LS44MDM4OTI2cy0zLjU0NTc3NDE0LTEwLjEwMzkxMzMzLjI2ODA5Njc5LTEzLjkxNzc4NDI2IDkuOTU5ODY0NzUtMy45MzIyNjA1NSAxMy45MTc3ODQyNS0uMjY4MDk2NzkgNC4zMTI5MTI3IDkuODAxMDM4ODUuODAzODkyNiAxMy44OTcxMzQ2NXptLTIyLjIyMzQyODAzLTEyLjYzNjc3NTVjMCAyLjI1Mjc1MjUuODk0OTAyNDIgNC40MTMyMzggMi40ODc4Mzg5NSA2LjAwNjE3NDYgMS41OTI5MzY1MyAxLjU5MjkzNjUgMy43NTM0MjIwOSAyLjQ4NzgzODkgNi4wMDYxNzQ0OCAyLjQ4NzgzODkgNC42OTExMTQyIDAgOC40OTQwMTM1LTMuODAyODk5NCA4LjQ5NDAxMzUtOC40OTQwMTM1IDAtNC42OTExMTQwNy0zLjgwMjg5OTMtOC40OTQwMTM0My04LjQ5NDAxMzUtOC40OTQwMTM0My00LjY5MTExNDA2IDAtOC40OTQwMTM0MyAzLjgwMjg5OTM2LTguNDk0MDEzNDMgOC40OTQwMTM0M3oiIGZpbGw9IiMzNTM0MzQiIHN0cm9rZT0iIzM1MzQzNCIgc3Ryb2tlLXdpZHRoPSIuMjUiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDI0LjQgMCkiLz48L3N2Zz4=);
        background-repeat: no-repeat;
        background-position: center center;
        border-color: $color-black;
        &:hover {
          background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMjUgMjUiIHdpZHRoPSIyNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjMuOTczNjg5NSAyMy4yNjkyODU3Yy4xOTUxODExLjE5NTE4MTIuMjcxNDA4LjQ3OTY2MzkuMTk5OTY2OC43NDYyODY0LS4wNzE0NDEzLjI2NjYyMjQtLjI3OTY5NzEuNDc0ODc4Mi0uNTQ2MzE5NS41NDYzMTk1LS4yNjY2MjI1LjA3MTQ0MTItLjU1MTEwNTItLjAwNDc4NTctLjc0NjI4NjQtLjE5OTk2NjlsLTYuMTExODI4Ny02LjExMTgyODdjLTQuMDk2MDk1OCAzLjUwOTAyMDEtMTAuMjMyOTcwODggMy4xNTQwMjY5LTEzLjg5NzEzNDY0LS44MDM4OTI2cy0zLjU0NTc3NDE0LTEwLjEwMzkxMzMzLjI2ODA5Njc5LTEzLjkxNzc4NDI2IDkuOTU5ODY0NzUtMy45MzIyNjA1NSAxMy45MTc3ODQyNS0uMjY4MDk2NzkgNC4zMTI5MTI3IDkuODAxMDM4ODUuODAzODkyNiAxMy44OTcxMzQ2NXptLTIyLjIyMzQyODAzLTEyLjYzNjc3NTVjMCAyLjI1Mjc1MjUuODk0OTAyNDIgNC40MTMyMzggMi40ODc4Mzg5NSA2LjAwNjE3NDYgMS41OTI5MzY1MyAxLjU5MjkzNjUgMy43NTM0MjIwOSAyLjQ4NzgzODkgNi4wMDYxNzQ0OCAyLjQ4NzgzODkgNC42OTExMTQyIDAgOC40OTQwMTM1LTMuODAyODk5NCA4LjQ5NDAxMzUtOC40OTQwMTM1IDAtNC42OTExMTQwNy0zLjgwMjg5OTMtOC40OTQwMTM0My04LjQ5NDAxMzUtOC40OTQwMTM0My00LjY5MTExNDA2IDAtOC40OTQwMTM0MyAzLjgwMjg5OTM2LTguNDk0MDEzNDMgOC40OTQwMTM0M3oiIGZpbGw9IiMwMDhDQzgiIHN0cm9rZT0iIzAwOENDOCIgc3Ryb2tlLXdpZHRoPSIuMjUiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDI0LjQgMCkiLz48L3N2Zz4=);
        }
        @media only screen and (max-width: 1140px) {
          height: 42px;
        }
        @media only screen and (max-width: $breakpoint-tablet-large) {
          margin-left: $spacing-xsmall;
        }
      }
      &.form-element--white {
        button {
          border-color: $color-white;
          background: transparent;
          background-image: url("data:image/svg+xml,%0A%3Csvg width='23px' height='23px' viewBox='0 0 23 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M22.2878339,21.7634848 C22.4708162,21.9464671 22.5422789,22.2131697 22.4753028,22.4631282 C22.4083266,22.7130867 22.2130867,22.9083266 21.9631282,22.9753028 C21.7131697,23.0422789 21.4464671,22.9708162 21.2634848,22.7878339 L15.5336453,17.0579944 C11.6935555,20.3477007 5.94023514,20.0148946 2.50508162,16.3043451 C-0.930071909,12.5937955 -0.819081639,6.83192635 2.75642236,3.25642236 C6.33192635,-0.319081639 12.0937955,-0.430071909 15.8043451,3.00508162 C19.5148946,6.44023514 19.8477007,12.1935555 16.5579944,16.0336453 L22.2878339,21.7634848 Z M1.45337013,9.91650776 C1.45337013,12.0284632 2.29234115,14.0539184 3.78571914,15.5472964 C5.27909714,17.0406744 7.30455235,17.8796454 9.41650776,17.8796454 C13.8144272,17.8796454 17.3796454,14.3144272 17.3796454,9.91650776 C17.3796454,5.51858829 13.8144272,1.95337013 9.41650776,1.95337013 C5.01858829,1.95337013 1.45337013,5.51858829 1.45337013,9.91650776 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='Mainpage' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='mobile_Suche' transform='translate(-313.000000, -323.000000)'%3E%3Cg id='Icons/Search' transform='translate(313.000000, 323.000000)'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Combined-Shape' fill='%23FFFFFF' fill-rule='nonzero' transform='translate(11.250000, 11.750000) scale(-1, 1) translate(-11.250000, -11.750000) ' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
          &:hover,
          &:focus {
            border-color: $color-blue-light;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='23px' height='23px' viewBox='0 0 23 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M22.2878339,21.7634848 C22.4708162,21.9464671 22.5422789,22.2131697 22.4753028,22.4631282 C22.4083266,22.7130867 22.2130867,22.9083266 21.9631282,22.9753028 C21.7131697,23.0422789 21.4464671,22.9708162 21.2634848,22.7878339 L15.5336453,17.0579944 C11.6935555,20.3477007 5.94023514,20.0148946 2.50508162,16.3043451 C-0.930071909,12.5937955 -0.819081639,6.83192635 2.75642236,3.25642236 C6.33192635,-0.319081639 12.0937955,-0.430071909 15.8043451,3.00508162 C19.5148946,6.44023514 19.8477007,12.1935555 16.5579944,16.0336453 L22.2878339,21.7634848 Z M1.45337013,9.91650776 C1.45337013,12.0284632 2.29234115,14.0539184 3.78571914,15.5472964 C5.27909714,17.0406744 7.30455235,17.8796454 9.41650776,17.8796454 C13.8144272,17.8796454 17.3796454,14.3144272 17.3796454,9.91650776 C17.3796454,5.51858829 13.8144272,1.95337013 9.41650776,1.95337013 C5.01858829,1.95337013 1.45337013,5.51858829 1.45337013,9.91650776 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='Mainpage' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='mobile_Suche' transform='translate(-313.000000, -323.000000)'%3E%3Cg id='Icons/Search' transform='translate(313.000000, 323.000000)'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Combined-Shape' fill='%23008CC8' fill-rule='nonzero' transform='translate(11.250000, 11.750000) scale(-1, 1) translate(-11.250000, -11.750000) ' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  label {
    @include label;
  }
  input[type='text'] {
    @include input-text;
  }
  input[type='password'] {
    @include input-text;
  }
  input[type='tel'] {
    @include input-text;
  }
  input[type='email'] {
    @include input-text;
  }
  input[type='date'] {
    @include input-text;
  }

  input[type='checkbox'] {
    @include checkbox;
  }
  textarea {
    @include textarea;
  }

  select {
    @include input-text;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml,%0A%3Csvg width='13px' height='7px' viewBox='0 0 13 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Konferenzen-und-Seminare' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Konferenzen_und_Seminare---Weiterbildungsradar' transform='translate(-950.000000, -561.000000)' fill='%23353434' fill-rule='nonzero'%3E%3Cg id='Group' transform='translate(201.000000, 510.000000)'%3E%3Cg id='PullDown' transform='translate(528.000000, 3.000000)'%3E%3Cpolygon id='Rectangle' points='223.048336 48 221 48 227.5 55 234 48 231.951664 48 227.5 52.7940997'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      white no-repeat 96% !important;
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }

  .form-element--white {
    label {
      color: $color-white;
    }
    input[type='text'] {
      background-color: transparent;
      border-color: $color-white;
      color: $color-white;
      &:hover {
        color: $color-white;
        border-color: $color-blue-light;
      }
      &:focus,
      &:focus::placeholder {
        color: $color-blue-light;
        border-color: $color-blue-light;
      }
      &::placeholder {
        color: $color-white;
      }
      &:hover::placeholder {
        color: $color-blue-light;
      }
    }
  }
}

/* Datepickr Styling */

.flatpickr-calendar {
  border-radius: 0 !important;
}

.flatpickr-months {
  height: 38px;
  padding-top: 4px;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  top: 4px;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $color-blue-light;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: $color-grey-mid;
  background: $color-grey-mid;
}
.flatpickr-day.today {
  border-color: $color-grey-mid;
}

span.flatpickr-weekday {
  color: $color-black;
  font-size: 14px;
  font-weight: 500;
}
.flatpickr-current-month {
  font-size: 16px;
  height: 32px;
  span.cur-month {
    font-weight: 500;
  }
}

.flatpickr-day {
  font-weight: 500;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  border-color: $color-blue-light;
  background-color: $color-blue-light;
}
